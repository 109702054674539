<template>
  <el-dialog title="取消订单" :visible.sync="cancelOrderDiaVisible" width="35%">
    <div>
      <div>取消订单后，本单享有的优惠可能会一并取消，是否继续？</div>
      <div>请选择取消订单的原因（必选）：</div>

      <div class="btn flex jc-center flex-wrap">
        <div
          class="reason mt-15 mr-10"
          :class="{ blueColor: index == currentIndex }"
          v-for="(item, index) in list"
          :key="index"
          @click="chooseReason(item)"
        >
          {{ item.value }}
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelOrderDiaVisible = false">取 消</el-button>
      <el-button type="primary" @click="cancelSubimt">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: ["orderId"],
  data() {
    return {
      currentIndex: "",
      cancelOrderDiaVisible: false,
      reason: "",
      list: [
        { value: "配送信息有误", index: 0 },
        { value: "商品买错了", index: 1 },
        { value: "重复下单/误下单", index: 2 },
        { value: "其他渠道价格更低", index: 3 },
		{ value: "不想买了", index: 4 },
        { value: "其它原因", index: 6 },
      ],
    };
  },
  methods: {
    // 取消原因
    chooseReason(item) {
      this.currentIndex = item.index;
      this.reason = item.value;
    },
    cancelSubimt() {
      this.$postJson("/shop-api/mall/goods-order/cancel", {
        reason: this.reason,
        id: this.orderId,
      }).then((data) => {
        if(data.code==1000){
        this.cancelOrderDiaVisible = false;
        this.$emit('cancelSubimt1')
         this.$message({
          message: '取消成功',
          type: 'success'
        });
        }else{
          this.$message(data.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog .el-dialog__footer {
  text-align: center;
}
.reason {
  width: 210px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  cursor: default;
  border: 1px solid #dddddd;
}
.blueColor {
  color: #2388FF;
  border: 1px solid #2388FF;
}
</style>